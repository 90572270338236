import { DateTime } from 'luxon';
import { formatDollar } from '../../functions/helpers';

const Info = (props) => {
  return (
    <div className="book-info">
      <table>
          <tbody>
          {props.authorName && <tr><td>Author:</td><td>{props.authorName}</td></tr>}
          {props.publishDate && <tr><td>Date:</td><td>{DateTime.fromISO(props.publishDate).setLocale("en-AU").toLocaleString()}</td></tr>}
          <tr><td>ISBN:</td><td>{props.isbn}</td></tr>
          <tr><td>RRP Price:</td><td>{formatDollar(props.price)}</td></tr>
          <tr><td>Category:</td><td>{props.category}</td></tr>
          <tr><td>Format:</td><td>{props.format}</td></tr>
          <tr><td>Format size:</td><td>{props.formatSize}</td></tr>
          <tr><td>Page extent:</td><td>{props.pages}</td></tr>
          <tr><td>Imprint:</td><td>{props.imprint}</td></tr>
          </tbody>
      </table>
    </div>
  );
};
export default Info;
