import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { request } from '../../functions/apiRequestWrapper';
import { formatDollar, getAuthorNameFL } from '../../functions/helpers';
import Loader from '../../components/Loader';
import BookListItem from '../../components/BookListItem';
import ReleaseListItem from '../../components/ReleaseListItem';
import BookCart from '../../components/BookCart';
import Breadcrumb from '../../components/Breadcrumb';
import Button from 'react-bootstrap/Button';
import {
    ChevronLeft,
    ChevronRight,
    Fullscreen,
    FullscreenExit
} from 'react-bootstrap-icons';
import { DateTime } from 'luxon';
import { PATH_CATALOGUE } from '../../constants';

import BookCoverWithPreview from './BookCoverWithPreview';
import Info from './Info';
import Details from './Details';

import { db } from '../../db';

const initialState = {
  isLoadingBook: true,
  isOffline: false,
  isLoadingOtherBooks: false,
  isLoadingSection: false,
  isLoadingReleases: false,
  internalPreviewImages: [],
  marketingImages: [],
  otherBooks: [],
  sectionBooks: [],
  releases: [],
  videos: []
};

class Book extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getBook();
        if (!this.props.isInRelease) {
            setTimeout(() => {window.scrollTo(0, 0);}, 100);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.isbn !== this.props.match.params.isbn) {
            this.setState(initialState, this.getBook);
        }
    }

    getBook = () => {
        const { match } = this.props;
        request(
            `${process.env.REACT_APP_API}/1/product/${match.params.isbn}`
        ).then((data) => {
            data.authorNameFL = getAuthorNameFL(data.authorName);
            this.setState({ ...data, isLoadingBook: false }, () => {
                if (typeof this.props.onLoad === "function") { this.props.onLoad(data.title); }
            });
            this.getOtherBooks(data.authorName, data.isbn); // get other books by this author
            this.getReleases(data.releases); // get releases this book is in
            this.getVideo(data.isbn);
        }).catch((error) => {
            db.products.get(match.params.isbn).then((data) => {
                // console.log("using offline data");
                data.authorNameFL = getAuthorNameFL(data.authorName);
                this.setState({ ...data, isLoadingBook: false, isOffline: true }, () => {
                    if (typeof this.props.onLoad === "function") { this.props.onLoad(data.title); }
                });
                this.getOtherBooks(data.authorName, data.isbn, true);
                this.getReleases(data.releases, true);
            }).catch(() => {
                this.setState({ isLoadingBook: false });
            });
        });
    }

    getVideo = (isbn) => {
        request(
            `${process.env.REACT_APP_API}/1/cms/videos/${isbn}`
        ).then((data) => {
            // console.log(data);
            this.setState({ videos: data });
        }).catch((error) => {
            console.error(error);
        });
    }

    getOtherBooks = (authorName, isbn, offline) => {
        this.setState({ isLoadingOtherBooks: true });
        const excludeFormats = ['CD', 'SLI', 'AUD','1PART','2PART','3PART','4PART','5PART','6PART','7PART','8PART','9PART','10PART','11PRT','12PRT','DAO','POS','SHRNK'];
        const statusOpts = ["In Stock", "Print On Demand", "Out Of Stock", "Out Of Stock – Reprint Under Consideration"];
        let today = new Date();
        let year = today.getFullYear() + 6;
        if (offline) {
            // console.log("getOtherBooks offline");
            db.products.where({authorName: authorName}).filter(o => {
                let r = o.isbn !== isbn;
                if (r && o.format) {
                    if (o.format === "POS" || o.format === "1PART") {
                        r = o.status && o.status === "Not Yet Published";
                    } else {
                        r = excludeFormats.indexOf(o.format) === -1;
                    }
                }
                if (r && o.status) {
                    if (o.status === "Not Yet Published") {
                        r = ["POS","1PART"].indexOf(o.format) > -1;
                    } else {
                        r = statusOpts.indexOf(o.status) > -1;
                    }
                }
                if (r && o.publishDate) {
                    r = DateTime.fromISO(`${year}-12-31`) > DateTime.fromISO(o.publishDate);
                }
                return r;
            }).toArray().then((books) => {
                // console.log("db products where authorName and isbn", books);
                this.setState({ otherBooks: books.slice(0,9), isLoadingOtherBooks: false });
            });
        } else {
            let excludeFormatsString = `${excludeFormats.map(f => `format ne '${f}'`).join(" and ")}`;
            let statusString = `${statusOpts.map(s => `(InStock eq '${s}')`).join(" or ")}`;
            // (InStock eq 'In Stock') or (InStock eq 'Print On Demand')
            request(
                `${process.env.REACT_APP_API}/1/product?$top=10&$orderby=publishDate desc&$filter=contains(AuthorName, '${encodeURIComponent(authorName.replace("'", "''"))}') and isbn ne '${isbn}' and cast(PublishDate, 'Edm.DateTimeOffset') le ${year}-12-31 and ((${excludeFormatsString} and (${statusString})) or ((format  eq 'POS' or format eq '1PART') and InStock eq 'Not Yet Published'))`
            ).then((data) => {
                this.setState({ otherBooks: data.items, isLoadingOtherBooks: false });
            }).catch((error) => {
                console.error(error);
                this.setState({ isLoadingOtherBooks: false });
            });
        }
    }

    getReleases = (releaseIDs, offline) => {
        let n = releaseIDs.length > 4 ? 4 : releaseIDs.length;
        if (offline) {
            // console.log("getReleases offline");
            db.releases.orderBy("yyyymm").reverse().filter(o => releaseIDs.indexOf(o.slug) > -1).toArray().then((releases) => {
                // console.log("db releases filter slug", releases);
                this.setState({releasesList: releases});
            });
        } else {
            let r = [];
            r.length = n;
            for (let i = 0; i < n; i++) {
                request(
                    `${process.env.REACT_APP_API}/1/releases/${releaseIDs[i]}`
                ).then((data) => {
                    let item = { catalogue: data.catalogue, imageUrls: data.imageUrls, slug: data.slug };
                    r[i] = item;
                    this.setState({ releasesList: r });
                }).catch((error) => {
                    console.error(error);
                });
            }
        }
    }
    
    scrollToVideo = () => {
        setTimeout(() => {
            document.getElementById("bookvideostop").scrollIntoView({behavior: "auto"});
        }, 100);
    }

    render() {
        if (this.state.isLoadingBook) {
            return (
                <div className="page book"><Loader type="placeholder" /></div>
            );
        }

        let crumbs = [{ link: PATH_CATALOGUE, label: "Catalogue" }];

        const { match, displayMode, isInRelease } = this.props;
        const { otherBooks, releasesList, productSummary, productDetail, aboutAuthor, marketingPoints, videos } = this.state;
        const bookHasDetails = Boolean(
            productSummary ||
            productDetail ||
            aboutAuthor ||
            marketingPoints ||
            (videos && videos.length > 0)
        );
        return (
            <div className={classnames("page book mb-5", {"container-fluid px-3": !isInRelease})}>
                {!isInRelease && <Breadcrumb crumbs={crumbs}>{this.state.isbn}</Breadcrumb>}

                <div className={classnames("book-header", {"mt-3": !isInRelease})}>
                    <div className="text-right p-2">
                        {this.props.goToBook && <>
                            {this.props.prevBook &&
                                <Button variant="primary" className="book-nav-btn" onClick={() => {this.props.goToBook(this.props.prevBook);}}><ChevronLeft size={20} /></Button>
                            }
                            {this.props.nextBook &&
                                <Button variant="primary" className="book-nav-btn" onClick={() => {this.props.goToBook(this.props.nextBook);}}><ChevronRight size={20} /></Button>
                            }
                        </>}
                        {this.props.isInRelease && <Button className="toggle-display-btn" variant="outline-primary" onClick={this.props.toggleDisplayMode}>
                            {!displayMode && <Fullscreen size={20} />}
                            {displayMode && <FullscreenExit size={20} />}
                        </Button>}
                    </div>
                    
                    <div className="row justify-content-center">
                        
                        <div className="col">
                            <div className="row">
                                <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                                    <BookCoverWithPreview
                                        isbn={match.params.isbn}
                                        isOffline={this.state.isOffline}
                                        imageUrl={this.state.imageUrl}
                                        onClickVideo={videos?.length > 0 ? this.scrollToVideo : null}
                                        hasImages={!!this.state.hasInternalImage}
                                        hasMarketing={!!this.state.hasMarketingMaterials}
                                    />
                                    <div className="d-none d-sm-block">
                                        <Info {...this.state} isbn={match.params.isbn} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="book-text pb-3">
                                        <h2 className="mb-3 mt-3 mt-sm-0">{this.state.title}</h2>
                                        <div className="row">
                                            <div className="col-12 col-lg">
                                                {this.state.authorNameFL && this.state.authorNameFL !== '' &&
                                                    <h5 className="mb-3">By {this.state.authorNameFL}</h5>
                                                }
                                                <div className="price-wrapper">
                                                  <h5 className="">RRP {formatDollar(this.state.price)}</h5>
                                                  {this.state.inStock && <span className={classnames("stock-level", this.state.inStock.toLowerCase().replace(/ /g, "-"))}>{this.state.inStock}</span>}
                                                  <div className="book-cart-wrapper">
                                                    <BookCart isbn={match.params.isbn} appendKey={'page'} btnSize="md" alwaysShowBtn />
                                                  </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-sm-none">
                                                <Info {...this.state} isbn={match.params.isbn} />
                                            </div>
                                        </div>

                                    </div>
                                    <div id="bookdetailstop" />
                                    {bookHasDetails && 
                                        <Details
                                            productSummary={productSummary}
                                            productDetail={productDetail}
                                            aboutAuthor={aboutAuthor}
                                            authorNameFL={this.state.authorNameFL}
                                            marketingPoints={marketingPoints}
                                            videos={videos}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        
                        {(otherBooks && otherBooks.length > 0) && <div className="other-books d-none d-lg-block col-auto">
                            <h4 className="mb-3">Other books by {this.state.authorNameFL}</h4>
                            <div className="other-books__list">
                                {otherBooks.map((product, i) => {
                                    return (<div key={product.isbn} className="pb-5">
                                        <BookListItem {...product} appendKey={'other'} showFullInfo />
                                    </div>);
                                })}
                            </div>
                        </div>}
                        
                    </div>
                </div>

                {(otherBooks && otherBooks.length > 0) && <div className="other-books d-lg-none">
                    <h2 className="my-3">Other books by {this.state.authorNameFL}</h2>
                    <div className="row product-row">
                        {otherBooks.map((product, i) => {
                            return (
                                <div key={product.isbn} className="col-6 col-sm-4 col-md-3 mb-3">
                                    <BookListItem {...product} appendKey={'other'} showFullInfo />
                                </div>
                            );
                        })}
                    </div>
                </div>}

                {(releasesList && releasesList.length > 0) && <hr className="my-5 divider" />}

                {(releasesList && releasesList.length > 0) && <div className="list releases">
                    <h2 className="mb-3">Featured in Releases</h2>
                    <div className="row product-row">
                        {releasesList.map(release => {
                            return (
                                <div key={release.slug} className="col-6 col-sm-4 col-md-3">
                                    <ReleaseListItem release={release} />
                                </div>
                            );
                        })}
                    </div>
                </div>}

            </div>
        );
    };
}

export default withRouter(Book);
